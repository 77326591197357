import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import React, { useContext, useState, useEffect } from 'react';
import API from "../ApiInterface";
import AuthContext from "../store/auth-context";
import "../styles/eapSurveyResults.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const formatJustDate = (isoString) => {
  if (!isoString) return "";
  const dateObj = new Date(isoString);
  const monthNames = [
    "January","February","March","April","May","June",
    "July","August","September","October","November","December"
  ];
  const month = monthNames[dateObj.getMonth()];
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  return `${month} ${day}, ${year}`;
};

const openTextInNewTab = (content, pageTitle = "Content") => {
  const newTab = window.open("", "_blank");
  if (newTab) {
    newTab.document.write(`
      <html>
        <head><title>${pageTitle}</title></head>
        <body>
          <pre style="white-space: pre-wrap; word-wrap: break-word;">
${content}
          </pre>
        </body>
      </html>
    `);
    newTab.document.close();
  }
};

const arrayDiff = (arr1, arr2) => {
  const set1 = new Set(arr1);
  return arr2.filter((item) => !set1.has(item));
};

const getDiffOfSurveyData = (
  surveyData1,
  surveyData2,
  fromDateStr = "",
  toDateStr = ""
) => {
  if (!surveyData1 || !surveyData2) return null;
  if (!surveyData1.stats || !surveyData2.stats) return null;

  const newStats = surveyData2.stats.map((stat2, i) => {
    const stat1 = surveyData1.stats[i];
    if (!stat1) return stat2;

    const { type } = stat2;

    if (type === "choice" || type === "select" || type === "scale") {
      const choices1 = stat1.choices || {};
      const choices2 = stat2.choices || {};
      const allKeys = new Set([...Object.keys(choices1), ...Object.keys(choices2)]);
      const newChoices = {};
      allKeys.forEach((k) => {
        const val1 = choices1[k] || 0;
        const val2 = choices2[k] || 0;
        newChoices[k] = val2 - val1;
      });

      return {
        ...stat2,
        choices: newChoices,
        description: (stat2.description || "").replace("(Between Dates)", "").trim(),
      };
    }
    else if (type === "text") {
      const newStat = { ...stat2 };
      newStat.description = (stat2.description || "").replace("(Between Dates)", "").trim();
      newStat.percentPositive = stat2.percentPositive - stat1.percentPositive;
      newStat.percentNegative = stat2.percentNegative - stat1.percentNegative;
      newStat.percentNeutral = stat2.percentNeutral - stat1.percentNeutral;
      newStat.percentMixed = stat2.percentMixed - stat1.percentMixed;
      newStat.numberResponded = stat2.numberResponded - stat1.numberResponded;
      newStat.rawResponses = arrayDiff(stat1.rawResponses, stat2.rawResponses);
      newStat.keyPhrases = arrayDiff(stat1.keyPhrases, stat2.keyPhrases);

      newStat.fromDate = fromDateStr;
      newStat.toDate = toDateStr;
      newStat.aiSummaryFrom = stat1.aiSummary || "";
      newStat.aiSummaryTo = stat2.aiSummary || "";

      return newStat;
    } else {
      return stat2;
    }
  });

  const userCountDiff = (surveyData2.userCount || 0) - (surveyData1.userCount || 0);
  return {
    ...surveyData2,
    userCount: userCountDiff,
    stats: newStats,
  };
};

const textBarColors = [ "#4caf50", "#f44336", "#2196f3", "#ff9800" ];


const horizontalBarOptions = {
  indexAxis: "y",
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      max: 100,
      grid: { display: false },
      ticks: {
        callback: (value) => value + "%",
      },
    },
    y: {
      grid: { display: false },
    },
  },
  plugins: {
    legend: { display: false },
    title: { display: false },
  },
};

const getTextSentimentChartData = (stat) => {
  if (!stat) {
    return {
      labels: ["Positive", "Negative", "Neutral", "Mixed"],
      datasets: [{ data: [0, 0, 0, 0], backgroundColor: textBarColors }],
    };
  }
  return {
    labels: ["Positive", "Negative", "Neutral", "Mixed"],
    datasets: [
      {
        label: "Sentiment (%)",
        data: [
          stat.percentPositive || 0,
          stat.percentNegative || 0,
          stat.percentNeutral || 0,
          stat.percentMixed || 0,
        ],
        backgroundColor: textBarColors,
        borderWidth: 1,
      },
    ],
  };
};

const generateCompareChartData = (leftStat, rightStat, leftLabel, rightLabel) => {
  const isScale = (leftStat?.type === "scale" || rightStat?.type === "scale");

  const lChoices = leftStat?.choices || {};
  const rChoices = rightStat?.choices || {};
  const allKeys = new Set([...Object.keys(lChoices), ...Object.keys(rChoices)]);
  const sortedKeys = [...allKeys].sort();

  if (isScale && sortedKeys.length > 0) {
    if (leftStat?.minText && sortedKeys.length > 0) {
      sortedKeys[0] = `${sortedKeys[0]}(${leftStat.minText})`;
    }
    if (leftStat?.maxText && sortedKeys.length > 1) {
      const lastIndex = sortedKeys.length - 1;
      sortedKeys[lastIndex] = `${sortedKeys[lastIndex]}(${leftStat.maxText})`;
    }
  }

  const leftData = sortedKeys.map((label) => {
    const numericKey = label.split("(")[0]; 
    return lChoices[numericKey] || 0;
  });

  const rightData = sortedKeys.map((label) => {
    const numericKey = label.split("(")[0];
    return rChoices[numericKey] || 0;
  });

  return {
    labels: sortedKeys,
    datasets: [
      {
        label: leftLabel,
        data: leftData,
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
      {
        label: rightLabel,
        data: rightData,
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };
};

const renderCompareTextBlock = (stat, sideLabel) => {
  if (!stat) {
    return <p>{sideLabel} data not available</p>;
  }
  const data = getTextSentimentChartData(stat);

  return (
    <div>
      <div style={{ width: "250px", height: "125px", margin: "0 auto 15px" }}>
        <Bar data={data} options={horizontalBarOptions} />
      </div>
      <p style={{ marginBottom: "15px", lineHeight: "1.5" }}>
        {stat.aiSummary}
      </p>
      <Stack direction="row" spacing={2} justifyContent="center">
        <Button
          onClick={() =>
            openTextInNewTab(
              stat.keyPhrases.join("\n"),
              sideLabel + " Key Phrases"
            )
          }
        >
          Key Phrases
        </Button>
        <Button
          onClick={() =>
            openTextInNewTab(
              stat.rawResponses.join("\n"),
              sideLabel + " Raw Responses"
            )
          }
        >
          Raw Responses
        </Button>
      </Stack>
    </div>
  );
};

const renderCompareQuestionBlock = (leftStat, rightStat, leftDateLabel, rightDateLabel) => {
  if (!leftStat && !rightStat) return null;
  const questionType = leftStat?.type || rightStat?.type || "choice";
  const questionDescription = leftStat?.description || rightStat?.description || "Question";

  if (["choice", "select", "scale"].includes(questionType)) {
    const chartData = generateCompareChartData(leftStat, rightStat, leftDateLabel, rightDateLabel);
    return (
      <div className="chart-item">
        <h3 className="chart-title">{questionDescription}</h3>
        <Bar
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: { display: true },
            },
          }}
        />
      </div>
    );
  } else if (questionType === "text") {
    return (
      <div className="chart-item">
        <h3 className="chart-title">{questionDescription}</h3>
        <div
          style={{
            display: "flex",
            gap: "2rem",
            justifyContent: "space-evenly",
            alignItems: "stretch",
          }}
        >
          <div style={{ flex: 1 }}>
            <p style={{ fontWeight: "bold", marginBottom: "8px" }}>
              {leftDateLabel} - {leftStat?.numberResponded ?? 0} responses
            </p>
            {renderCompareTextBlock(leftStat, leftDateLabel)}
          </div>

          <div style={{ width: '1px', backgroundColor: '#ccc' }} />

          <div style={{ flex: 1 }}>
            <p style={{ fontWeight: "bold", marginBottom: "8px" }}>
              {rightDateLabel} - {rightStat?.numberResponded ?? 0} responses
            </p>
            {renderCompareTextBlock(rightStat, rightDateLabel)}
          </div>
        </div>
      </div>
    );
  }
  return null;
};


const EapSurveyResults = () => {
  const authCtx = useContext(AuthContext);

  const [tabValue, setTabValue] = useState(0);

  const [surveyData, setSurveyData] = useState(null);
  const [userCount, setUserCount] = useState(0);

  const [dates, setDates] = useState([]);
  const [leftSelectedDate, setLeftSelectedDate] = useState("");
  const [leftSurveyData, setLeftSurveyData] = useState(null);
  const [leftUserCount, setLeftUserCount] = useState(0);

  const [rightSelectedDate, setRightSelectedDate] = useState("");
  const [rightSurveyData, setRightSurveyData] = useState(null);
  const [rightUserCount, setRightUserCount] = useState(0);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromSurveyData, setFromSurveyData] = useState(null);
  const [toSurveyData, setToSurveyData] = useState(null);
  const [bwdSurveyData, setBwdSurveyData] = useState(null);

  const getNewestSurveyData = () => {
    API.getNewestSurveyResponseRecord(authCtx.token)
      .then((data) => {
        if (data) {
          setSurveyData(data);
          setUserCount(data.userCount || 0);
        } else {
          console.error("Unexpected API response format:", data);
          setSurveyData(null);
          setUserCount(0);
        }
      })
      .catch((error) => {
        console.error("Error fetching newest survey data:", error);
        setSurveyData(null);
        setUserCount(0);
      });
  };

  const getAllDates = () => {
    API.getSurveyResponseRecordDates(authCtx.token)
      .then((res) => {
        setDates(res);
        if (res && res.length > 0) {
          setLeftSelectedDate(res[0]);
          setRightSelectedDate(res[res.length - 1]);
          setFromDate(res[0]);
          setToDate(res[res.length - 1]);
        }
      })
      .catch((error) => {
        console.error("Error fetching record dates:", error);
      });
  };

  const fetchSurveyDataByDate = (dateValue) => {
    if (!dateValue) return Promise.resolve(null);
    return API.getSurveyResponseRecordByDate(dateValue, authCtx.token);
  };

  useEffect(() => {
    getNewestSurveyData();
    getAllDates();
  }, []);

  useEffect(() => {
    if (tabValue === 1 && leftSelectedDate) {
      fetchSurveyDataByDate(leftSelectedDate).then((data) => {
        setLeftSurveyData(data);
        setLeftUserCount(data ? data.userCount || 0 : 0);
      });
    }
  }, [leftSelectedDate, tabValue]);

  useEffect(() => {
    if (tabValue === 1 && rightSelectedDate) {
      fetchSurveyDataByDate(rightSelectedDate).then((data) => {
        setRightSurveyData(data);
        setRightUserCount(data ? data.userCount || 0 : 0);
      });
    }
  }, [rightSelectedDate, tabValue]);

  useEffect(() => {
    if (tabValue !== 2) return;
    if (!fromDate || !toDate) {
      setBwdSurveyData(null);
      return;
    }
    Promise.all([fetchSurveyDataByDate(fromDate), fetchSurveyDataByDate(toDate)])
      .then(([fromData, toData]) => {
        setFromSurveyData(fromData);
        setToSurveyData(toData);
        if (fromData && toData) {
          const diffData = getDiffOfSurveyData(
            fromData,
            toData,
            formatJustDate(fromDate),
            formatJustDate(toDate)
          );
          setBwdSurveyData(diffData);
        } else {
          setBwdSurveyData(null);
        }
      })
      .catch((err) => {
        console.error("Error fetching b/w dates data:", err);
        setFromSurveyData(null);
        setToSurveyData(null);
        setBwdSurveyData(null);
      });
  }, [fromDate, toDate, tabValue]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderQuestionBlockCurrent = (stat) => {
    if (!stat) return null;

    if (stat.type === "text") {
      const textData = getTextSentimentChartData(stat);

      return (
        <div className="text-box">
          <p style={{ fontWeight: "bold", color: "#555" }}>
            Responses: {stat.numberResponded ?? 0}
          </p>
          <div style={{ width: "300px", height: "150px", margin: "0 auto 15px" }}>
            <Bar data={textData} options={horizontalBarOptions} />
          </div>

          {stat.fromDate && stat.toDate && (
            <>
              <p style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                <strong>{stat.fromDate}</strong><br/>
                {stat.aiSummaryFrom}
              </p>
              <div style={{ height: "1.5rem" }} />
              <p style={{ marginBottom: "15px", lineHeight: "1.5" }}>
                <strong>{stat.toDate}</strong><br/>
                {stat.aiSummaryTo}
              </p>
            </>
          )}

          {!stat.fromDate && !stat.toDate && (
            <p style={{ marginBottom: "15px", lineHeight: "1.5" }}>
              {stat.aiSummary}
            </p>
          )}

          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              onClick={() =>
                openTextInNewTab(stat.keyPhrases.join("\n"), "Key Phrases")
              }
            >
              Key Phrases
            </Button>
            <Button
              onClick={() =>
                openTextInNewTab(stat.rawResponses.join("\n"), "Raw Responses")
              }
            >
              Raw Responses
            </Button>
          </Stack>
        </div>
      );
    }
    else if (stat.type === "scale") {
      const labels = Object.keys(stat.choices || {});
      if (labels.length >= 2) {
        labels[0] += `(${stat.minText || "min"})`;
        labels[labels.length - 1] += `(${stat.maxText || "max"})`;
      }

      const dataVals = Object.values(stat.choices || {});
      const doughnutData = {
        labels,
        datasets: [
          {
            label: `Question: ${stat.description}`,
            data: dataVals,
            backgroundColor: [
              "rgba(255, 99, 132, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(255, 206, 86, 0.6)",
              "rgba(75, 192, 192, 0.6)",
              "rgba(153, 102, 255, 0.6)",
            ],
          },
        ],
      };
      return (
        <Doughnut
          data={doughnutData}
          options={{
            responsive: true,
            plugins: {
              legend: { display: true, position: "top" },
            },
          }}
        />
      );
    }
    else {
      // choice / select => vertical bar
      const labels = Object.keys(stat.choices || {});
      const dataVals = Object.values(stat.choices || {});
      const barData = {
        labels,
        datasets: [
          {
            label: `Question: ${stat.description}`,
            data: dataVals,
            backgroundColor: [
              "rgba(255, 99, 132, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(255, 206, 86, 0.6)",
              "rgba(75, 192, 192, 0.6)",
              "rgba(153, 102, 255, 0.6)",
            ],
          },
        ],
      };
      return (
        <Bar
          data={barData}
          options={{
            responsive: true,
            plugins: {
              legend: { display: false },
            },
          }}
        />
      );
    }
  };

  if (!surveyData && tabValue === 0) {
    return <p>Loading survey data...</p>;
  }

  return (
    <div>
      <h3 className="title">EAP Survey Results</h3>

      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          centered
          TabIndicatorProps={{
            style: { backgroundColor: "#DC4C64" },
          }}
        >
          <Tab
            label="Most Recent"
            sx={{
              color: "#9FA6B2",
              "&.Mui-selected": { color: "#DC4C64" },
            }}
          />
          <Tab
            label="Compare Results"
            sx={{
              color: "#9FA6B2",
              "&.Mui-selected": { color: "#DC4C64" },
            }}
          />
          <Tab
            label="From-To"
            sx={{
              color: "#9FA6B2",
              "&.Mui-selected": { color: "#DC4C64" },
            }}
          />
        </Tabs>
      </Box>

      {tabValue === 0 && surveyData && (
        <div>
          <div className="user-count-container">
            <h4 className="user-count-header">
              Users Responded: {userCount}
            </h4>
          </div>

          <div className="chart-grid">
            {surveyData.stats.map((stat, idx) => (
              <div key={idx} className="chart-item">
                <h3 className="chart-title">{stat.description}</h3>
                {renderQuestionBlockCurrent(stat)}
              </div>
            ))}
          </div>
        </div>
      )}

      {tabValue === 1 && (
        <div>
          <div className="date-selector-container">
            <div className="date-block">
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel id="left-date-select-label">Select Date</InputLabel>
                <Select
                  labelId="left-date-select-label"
                  id="left-date-select"
                  value={leftSelectedDate}
                  onChange={(e) => setLeftSelectedDate(e.target.value)}
                  label="Select Date"
                  autoWidth
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {dates.map((dt) => (
                    <MenuItem key={dt} value={dt}>
                      {formatJustDate(dt)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <h4 className="user-count-header" style={{ marginTop: "10px" }}>
                Users Responded: {leftUserCount}
              </h4>
            </div>

            <div className="date-block">
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel id="right-date-select-label">Select Date</InputLabel>
                <Select
                  labelId="right-date-select-label"
                  id="right-date-select"
                  value={rightSelectedDate}
                  onChange={(e) => setRightSelectedDate(e.target.value)}
                  label="Select Date"
                  autoWidth
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {dates.map((dt) => (
                    <MenuItem key={dt} value={dt}>
                      {formatJustDate(dt)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <h4 className="user-count-header" style={{ marginTop: "10px" }}>
                Users Responded: {rightUserCount}
              </h4>
            </div>
          </div>

          {(!leftSurveyData || !rightSurveyData) && (
            <p style={{ marginLeft: "20px" }}>Loading compare data...</p>
          )}

          {leftSurveyData && rightSurveyData && (
            <div className="chart-grid">
              {leftSurveyData.stats.map((leftStat, index) => {
                const rightStat = rightSurveyData.stats[index];
                const leftDateLabel = formatJustDate(leftSelectedDate);
                const rightDateLabel = formatJustDate(rightSelectedDate);

                return (
                  <div key={index}>
                    {renderCompareQuestionBlock(
                      leftStat,
                      rightStat,
                      leftDateLabel,
                      rightDateLabel
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}

      {tabValue === 2 && (
        <div>
          <div className="date-selector-container">
            <div className="date-block">
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel id="from-date-select-label">From Date</InputLabel>
                <Select
                  labelId="from-date-select-label"
                  id="from-date-select"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  label="From Date"
                  autoWidth
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {dates.map((dt) => (
                    <MenuItem key={dt} value={dt}>
                      {formatJustDate(dt)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="date-block">
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel id="to-date-select-label">To Date</InputLabel>
                <Select
                  labelId="to-date-select-label"
                  id="to-date-select"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  label="To Date"
                  autoWidth
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {dates.map((dt) => (
                    <MenuItem key={dt} value={dt}>
                      {formatJustDate(dt)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          {(!fromSurveyData || !toSurveyData) && (
            <p style={{ marginLeft: "20px", marginTop: "1rem" }}>
              Please select valid dates to see results.
            </p>
          )}

          {bwdSurveyData && (
            <div>
              <div className="user-count-container">
                <h4 className="user-count-header">
                  Users Responded: {bwdSurveyData.userCount}
                </h4>
              </div>

              <div className="chart-grid">
                {bwdSurveyData.stats.map((stat, idx) => (
                  <div key={idx} className="chart-item">
                    <h3 className="chart-title">{stat.description}</h3>
                    {renderQuestionBlockCurrent(stat)}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EapSurveyResults;
